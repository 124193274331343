import { SecurityResource } from '@greco/security';

export enum InvoiceResourceAction {
  READ = 'READ',
}

export const InvoiceResource: SecurityResource = {
  key: 'finance_invoice',
  module: 'Finance',

  title: 'Invoice',
  description: 'Manage invoices',

  context: class {
    studioId?: string;
    accountId?: string;
    userId?: string;
  },

  actions: [
    { key: InvoiceResourceAction.READ, title: 'Read', description: 'Ability to read/view invoices' }, //
  ],
};
