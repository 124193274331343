import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-perks-wrapper-page',
  template: `<greco-user-perks-page *grecoLet="user$ | async as user" [user]="user"></greco-user-perks-page>`,
})
export class PerksWrapperPage implements OnInit {
  constructor(private crumbs: BreadcrumbService, private route: ActivatedRoute, private userSvc: UserService) {}

  user$ = combineLatest([this.route.queryParams, this.userSvc.getSelf()]).pipe(
    switchMap(([params, self]) => {
      if (!params.user || !self?.isSuperAdmin) return of(null);
      return this.userSvc.getUser(params.user);
    })
  );

  ngOnInit() {
    setTimeout(() => this.crumbs.set([]));
  }
}
